<template>
  <v-main :class="$route.params.org_id" class="default-layout">
    <v-container fluid class="portfolio-sharing align-start pa-0 ma-0 pa-md-10 pa-sm-5 pa-xs-2 h-100">
      <app-bar
        v-if="showApplicationBar"
        :show-logo="showLogo"
        :show-language-switcher="showLanguageSwitcher"
        class="mx-0 mt-0"
      />
      <v-row class="portfolio-sharing__content position-relative" no-gutters>
        <v-col cols="12" md="12" class="fill-height">
          <slot />
        </v-col>
      </v-row>
      <div v-if="!showLogo" class="portfolio-sharing__logo">
        <n-img contain lazy :src="logoImage" alt="Njorda Logo" class="portfolio-sharing__logo__img" />
        <v-card
          v-if="showLogoTitle"
          color="transparent"
          flat
          class="mt-3 text-center text-md-left text-lg-left text-xl-left"
        >
          <span
            class="text-subtitle-1 text-sm-h6 text-md-h6 text-lg-h5 text-xl-h5 text-title-font primary--text text-uppercase font-weight-medium"
          >
            {{ $t("advisor.title") }}
          </span>
        </v-card>
      </div>
      <div
        v-if="layoutSettings?.backgroundImage"
        class="portfolio-sharing__background-illustration"
        :class="{
          'portfolio-sharing__background-illustration__fade': fadeBackgroundImage,
        }"
      >
        <n-img
          lazy
          :src="layoutSettings?.backgroundImage"
          aspect-ratio="16/9"
          alt="Portfolio Fetcher Background"
          contain
        />
      </div>
    </v-container>
    <v-btn
      v-if="canSendFeedback"
      class="floating-button"
      color="secondary"
      dark
      icon="n:comment-alert"
      @click.stop="openFeedbackDialog"
    />
    <feedback-dialog v-if="showFeedbackDialog" @close="closeFeedbackDialog" />
  </v-main>
</template>

<script lang="ts" setup>
import { useTheme } from "vuetify";
import { themes } from "@/themes";
import { setupAppConfiguration } from "@/utils/app-config";
import { useAppStore } from "~/store/app";
import { storeToRefs } from "pinia";
import { usePortfolioSharingStore } from "@/store/portfolioSharing";

const { $sentry, $setAppLocale } = useNuxtApp();
const { layoutSettings } = storeToRefs(useAppStore());
const { reportId } = storeToRefs(usePortfolioSharingStore());
const { updateLayoutSettings } = useAppStore();
const $route = useRoute();

const showFeedbackDialog = computed(() => {
  return layoutSettings.value?.isFeedbackDialogOpen;
});
const showLogo = computed(() => {
  return layoutSettings.value?.showLogo;
});
const showLogoTitle = computed(() => {
  return layoutSettings.value?.showLogoTitle;
});
const fadeBackgroundImage = computed(() => {
  return layoutSettings.value?.fadeBackgroundImage;
});
const showLanguageSwitcher = computed(() => {
  return layoutSettings.value?.showLanguageSwitcher;
});
const favicon = computed(() => {
  return layoutSettings.value?.favicon;
});
const title = computed(() => {
  return layoutSettings.value?.name;
});
const showApplicationBar = computed(() => {
  return showLogo.value ?? showLanguageSwitcher.value ?? false;
});
const logoImage = computed(() => {
  return layoutSettings.value?.logoImage;
});
const canSendFeedback = computed(() => {
  return !!reportId.value;
});

watch(
  $route,
  (newVal) => {
    $sentry.setContext("page path", {
      path: newVal.fullPath,
    });
  },
  {
    deep: true,
    immediate: true,
  },
);

useHead({
  link: [
    {
      hid: "icon",
      rel: "icon",
      type: "image/x-icon",
      href: favicon,
    },
  ],
  titleTemplate: title,
});

if ($route.params.org_id) {
  switch ($route.params.org_id) {
    case "avenirinvest":
      _setupAppConfiguration(themes.AVENIR_INVEST, "AVENIR_INVEST");
      break;
    case "njorda-white":
      _setupAppConfiguration(themes.NJORDA_MOCK_WHITE, "NJORDA_MOCK_WHITE");
      break;
    case "knoxcapital":
      _setupAppConfiguration(themes.KNOX_CAPITAL, "KNOX_CAPITAL");
      break;
    case "svp":
      _setupAppConfiguration(themes.SVP, "SVP");
      break;
    case "mm-test":
    case "mm":
      _setupAppConfiguration(themes.MAX_MATTHIESSEN, "MAX_MATTHIESSEN");
      break;
    default:
      break;
  }
}

$sentry.setContext("page path", {
  path: $route.fullPath,
});

function _setupAppConfiguration(appConfig, _theme) {
  const configuration = setupAppConfiguration(appConfig);
  useTheme().global.name.value = _theme;

  if (configuration.store.locale) {
    $setAppLocale(configuration.store.locale);
  }
  updateLayoutSettings({
    ...configuration.store,
  });
}
function closeFeedbackDialog() {
  updateLayoutSettings({
    isFeedbackDialogOpen: false,
  });
}
function openFeedbackDialog() {
  updateLayoutSettings({
    isFeedbackDialogOpen: true,
  });
}
</script>

<style lang="scss" scoped>
@use "sass:map" as map;
@import "vuetify/_tools";
@import "vuetify/lib/styles/settings/_variables";

.default-layout {
  max-height: 100vh !important;
  overflow-y: auto !important;
}

.floating-button {
  position: absolute;
  right: 40px;
  bottom: 40px;
  z-index: 100;
}

.portfolio-sharing {
  position: relative;

  &__content {
    z-index: 2;
    height: calc(100% - 20px) !important;
    overflow-y: auto;
  }
  &__background-illustration {
    position: absolute;
    width: 540px;
    bottom: 0;
    left: 0;
    z-index: -1 !important;

    &__fade {
      opacity: 0.7;
    }
  }
  &__logo {
    position: absolute;
    bottom: 50%;
    left: 140px;

    &__img {
      width: 440px;
    }
  }
}

@media #{map.get($display-breakpoints, 'xs')} {
  .portfolio-sharing {
    &__background-illustration {
      width: calc(100% - 25vw);
    }

    &__logo {
      top: 3%;
      left: 3%;
      &__img {
        width: 180px !important;
      }
    }

    &__content {
      height: calc(100% - 50px) !important;
    }
  }
}

@media #{map.get($display-breakpoints, 'sm')} {
  .portfolio-sharing__logo {
    top: 3%;
    left: 10%;
    &__img {
      width: 240px !important;
    }
  }
  .portfolio-sharing__content {
    height: calc(100% - 90px) !important;
  }
}

@media #{map.get($display-breakpoints, 'md')} {
  .portfolio-sharing__logo {
    &__img {
      width: 320px !important;
    }
  }
}
</style>
